import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Input, Label, Spinner,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import { fetchAccountTypes, updateAccountType } from "store/actions";
import { Link } from "react-router-dom";
import useModal from "hooks/useModal";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import Sidebar from "components/PayFiniticDashboardlLayout/Sidebar";
import AddMerchantModal from "./AddMerchantModal";
import { getMerchants } from "apis/merchants";


function PayFiniticDashboardMerchants(props) {
  const [accountType, setAccountType] = useState();
  const [docs, setDocs] = useState([]);
  const [durationOption, setDurationOption] = useState("");
  const [timeOption, setTimeOption] = useState("");
  const [merchantShow, setMerchantShow] = useState(false);
  const [editModal, toggleEditModal] = useModal();

  const {
    loading,
    // docs,
    page,
    totalDocs,
    totalPages,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    pagingCounter,
    prevPage,
    deleteLoading,
    deleteClearingCounter,
    // roles,
    // editClearingCounter,
    teamsPermissions
  } = useSelector((state) => ({
    loading: state.teamsReducer.loading || false,
    // docs: state.teamsReducer.docs || [],
    page: state.teamsReducer.page || 1,
    totalDocs: state.teamsReducer.totalDocs || 0,
    totalPages: state.teamsReducer.totalPages || 0,
    hasNextPage: state.teamsReducer.hasNextPage,
    hasPrevPage: state.teamsReducer.hasPrevPage,
    limit: state.teamsReducer.limit,
    nextPage: state.teamsReducer.nextPage,
    pagingCounter: state.teamsReducer.pagingCounter,
    prevPage: state.teamsReducer.prevPage,
    deleteLoading: state.teamsReducer.deleteLoading,
    deleteClearingCounter: state.teamsReducer.deleteClearingCounter,
    // roles: state.teamsReducer.rolesData,
    clearingCounter: state.teamsReducer.clearingCounter,
    // editClearingCounter: state.teamsReducer.editClearingCounter,
    teamsPermissions: state.Profile.teamsPermissions || {}
  }));
  const cryptoWalletsColumns = [
    {
      dataField: "id",
      text: "Id",
      formatter: (val) => <p>{val?.recordId}</p>
    },
    {
      dataField: "customer",
      text: "Customer",
      formatter: (val) => <Link to={`/clients/${val?.customerId?._id}`}>{val?.customerId?.firstName} {val?.customerId?.lastName}</Link>
    },
    // {
    //   dataField: "balance",
    //   text: "Balance",
    //   formatter: (item) => (
    //     captilazeFirstLetter(item.title)
    //   )
    // },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (item) => (
        <Link className="New Payment" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => { setAccountType(item); toggleEditModal() }}
          ></i>
        </Link>
      )
    },
  ];

  const dispatch = useDispatch();
  
  const [sizePerPage, setSizePerPage] = useState(10);
  
  useEffect(async() => {
    let data = await getMerchants();
    setDocs(data?.result?.docs);
  }, []);
  //   useEffect(() => {
  //     loadFeeGroups(1, sizePerPage);
  //   }, [sizePerPage, 1]);
  //   useEffect(()=>{
  //     dispatch(fetchMarketsStart({
  //       limit:1000,
  //       page:1
  //     }));
  //   }, []);
  //  const loadFeeGroups = (page, limit) => {
  //     dispatch(fetchFeeGroupStart({
  //       page,
  //       limit
  //     }));
  //   };
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Pay Finitic Dashboard Merchants
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          {/* <h2>Pay Finitic Dashboard Merchants</h2> */}
          <Row>
            <Col className="col-12">
              <Card>
                <Row>
                  <Col lg='12'>
                    <CardHeader className="d-flex flex-column gap-3">
                      <div className="d-flex justify-content-between  align-items-center">
                        <CardTitle className="color-primary">{("Merchants")} ({props.totalDocs})</CardTitle>
                        <AddMerchantModal show={merchantShow}/>
                      </div>
                    </CardHeader>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table  table-hover "
                        >
                          <Thead className="text-center table-light" >
                            <Tr>
                              {cryptoWalletsColumns?.map((column, index) =>
                                <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                              )}
                            </Tr>
                          </Thead>
                          <Tbody className="text-center">
                            {loading && <TableLoader colSpan={6} />}
                            {!loading && docs?.map((row, rowIndex) =>
                              <Tr key={rowIndex}>
                                {cryptoWalletsColumns?.map((column, index) =>
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                  </Td>
                                )}
                              </Tr>
                            )}
                            {/* <Tr>
                              <Td colSpan={3}>Comming Soon</Td>
                            </Tr> */}
                          </Tbody>
                        </Table>
                        <CustomPagination
                          totalPages={totalPages}
                          docs={docs}
                          sizePerPage={sizePerPage}
                          page={page}
                          totalDocs={totalDocs}
                          hasNextPage={hasNextPage}
                          hasPrevPage={hasPrevPage}
                          prevPage={prevPage}
                          nextPage={nextPage}
                          limit={limit}
                          pagingCounter={pagingCounter}
                          setSizePerPage={setSizePerPage}
                          // onChange={loadTeams}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  );
}

export default PayFiniticDashboardMerchants;
