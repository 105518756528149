import {
  FETCH_FEE_COLLECTED_START,
  FETCH_FEE_COLLECTED_SUCCESS,
  API_ERROR,
  ADD_FEES_COLLECTED_START,
  ADD_FEES_COLLECTED_SUCCESS,
  EDIT_FEE_COLLECTED_START,
  EDIT_FEE_COLLECTED_SUCCESS,
  DELETE_FEE_COLLECTED_START,
  DELETE_FEE_COLLECTED_SUCCESS,
  ADD_MODAL_CLEAR,
  EDIT_MODAL_CLEAR,
  DELETE_MODAL_CLEAR,
  FETCH_FEE_COLLECTED_AMOUNT_START,
  FETCH_FEE_COLLECTED_AMOUNT_SUCCESS
} from "./actionsType";
export const fetchFeeCollectedAmountStart = (params)=>{
  return {
    type:FETCH_FEE_COLLECTED_AMOUNT_START,
    payload:{ params }
  };
};
export const fetchFeeCollectedsAmountSuccess = (data)=>{
  return {
    type:FETCH_FEE_COLLECTED_AMOUNT_SUCCESS,
    payload:data
  };
};
export const fetchFeeCollectedStart = (params)=>{
  return {
    type:FETCH_FEE_COLLECTED_START,
    payload:{ params }
  };
};
export const fetchFeeCollectedsSuccess = (data)=>{
  return {
    type:FETCH_FEE_COLLECTED_SUCCESS,
    payload:data
  };
};
export const apiError = (error)=> {
  return {
    type:API_ERROR,
    payload:{ error }
  };
};
export const addFeeCollectedStart = (body)=>{
  return {
    type:ADD_FEES_COLLECTED_START,
    payload:body 
  };
};
export const addFeeCollectedSuccess = (data)=>{
  return {
    type:ADD_FEES_COLLECTED_SUCCESS,
    payload:data

  };
};
export const editFeeCollectedStart = (id, body)=>{
  return {
    type:EDIT_FEE_COLLECTED_START,
    payload:{
      id,
      body
    }
  };
};
export const editFeeCollectedSuccess = (data)=>{
  return {
    type:EDIT_FEE_COLLECTED_SUCCESS,
    payload:data 
  };
};
export const deleteFeeCollectedStart = (id)=>{
  return {
    type:DELETE_FEE_COLLECTED_START,
    payload:{ id }
  };
};
export const deleteFeeCollectedSuccess = (id)=>{
  return {
    type: DELETE_FEE_COLLECTED_SUCCESS,
    payload:{ id } 
  };
};
export const addModalClear = ()=>{
  return {
    type:ADD_MODAL_CLEAR
  };
};
export const editModalClear = ()=>{
  return {
    type:EDIT_MODAL_CLEAR,

  };
};
export const deleteModalClear = ()=>{
  return {
    type: DELETE_MODAL_CLEAR
  };
};