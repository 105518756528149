import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ seriesData = [], labelsData, }) => {
  const piechartColors = ["#777aca", "#5156be", "#a8aada", "#5156be", "#34c38f"];
  const [series, setSeries] = useState();
  const [options, setOptions] = useState();
  
  useEffect(()=>{
    if (labelsData) {
      const optionsData = {
        chart: {
          width: 227,
          height: 227,
          type: "donut",
        },
        labels: labelsData,
        colors: piechartColors,
        stroke: {
          width: 0,
        },
        legend: {
          show: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }],
      };
      setOptions(optionsData);
    }
  }, [labelsData]);

  useEffect(()=>{
    if (seriesData) {
      setSeries(seriesData);
    }
  }, [seriesData]);

  return (
    (options && series ) ?
      <ReactApexChart options={options} series={series} type="donut" height="227" />
      : ""
  );
};

export default PieChart;
