import * as axioHelper from "./api_helper";
import qs from "qs";

export async function fetchTotalFeeCollected (params){
  try {
    const result = await axioHelper.get(`/admin-wallet?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}