/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
  approveFxDeposit,
  fetchForexDeposits,
  rejectFxDeposit,
} from "store/forexTransactions/deposits/actions";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link, useLocation } from "react-router-dom";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { fetchForexDepositsGatewaysStart } from "store/forexGateway/actions";
import CustomDropdown from "components/Common/CustomDropDown";
// import Filter from "./FeesCollectedFilter";
import Badge from "components/Common/Badge";
import formatDate from "helpers/formatDate";
import ReceiptModal from "../Transactions/Forex/ReceiptModal";
import FeatherIcon from "feather-icons-react";
import TransactionModal from "components/Common/TransactionModal";
import { MetaTags } from "react-meta-tags";
import { fetchFeeCollectedStart } from "store/FeeCollected/actions";
import { fetchTotalFeeCollectedStart } from "store/adminWallet/actions";

function AdminWallet(props) {
  // get query paramerters from url
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState({});

  const depositApproveModal = (deposit) => {
    setSelectedDeposit(deposit);
    setApproveModal(true);
  };

  const approveDeposit = (deposit) => {
    dispatch(
      approveFxDeposit({
        id: deposit._id,
        customerId: deposit.customerId._id,
        amount: deposit.amount,
        note: deposit.note,
      })
    );
    setApproveModal(false);
  };

  const depositReject = (deposit) => {
    dispatch(
      rejectFxDeposit({
        id: deposit._id,
        customerId: deposit.customerId._id,
      })
    );
  };

  const columns = [
    {
      dataField: "customer",
      text: props.t("Merchant"),
      formatter: (val) => (
        <>
          {val?.customerName}
        </>
      ),
    },
    {
      dataField: "fees",
      text: props.t("Total Fees Collected"),
      formatter: (val) => <>{val?.totalCustomerFees}</>,
    },
  ];

  const loadTotalFeeCollected = (page, limit) => {
    dispatch(
      fetchTotalFeeCollectedStart({
        limit,
        page,
      })
    );
  };

  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  useEffect(() => {
    loadTotalFeeCollected(1, sizePerPage);
  }, [sizePerPage]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Wallet</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardHeader className="d-flex flex-column gap-3 ">
                      <div className="d-flex justify-content-between align-items-center">
                        <CardTitle className="color-primary">
                          {props.t("Admin Wallet")}
                          <FeatherIcon
                            icon="refresh-cw"
                            className="icon-lg ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              loadTotalFeeCollected(1, sizePerPage);
                            }}
                          />
                        </CardTitle>
                        <p className="color-primary fw-bold">{`Total Collected Fees: ${props.totalfeesCollected}`}</p>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table  table-hover "
                          >
                            <Thead className="text-center table-light">
                              <Tr>
                                {columns.map((column, index) => (
                                  <Th data-priority={index} key={index}>
                                    <span className="color-primary">
                                      {column.text}
                                    </span>
                                  </Th>
                                ))}
                              </Tr>
                            </Thead>

                            {props?.collectedFeesDocs?.length === 0 ? (
                              <Tbody
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {props.fetchLoading && (
                                  <TableLoader colSpan={4} />
                                )}
                                {!props.fetchLoading && (
                                  <>
                                    <Tr>
                                      <Td
                                        colSpan={"100%"}
                                        className="fw-bolder text-center"
                                      >
                                        <h3 className="fw-bolder text-center">
                                          No records
                                        </h3>
                                      </Td>
                                    </Tr>
                                  </>
                                )}
                              </Tbody>
                            ) : (
                              <Tbody
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {props.fetchLoading && (
                                  <TableLoader colSpan={4} />
                                )}
                                {!props.fetchLoading &&
                                  props?.collectedFeesDocs?.map((row, rowIndex) => (
                                    <Tr key={rowIndex}>
                                      {columns.map((column, index) => (
                                        <Td
                                          key={`${rowIndex}-${index}`}
                                          className="pt-4"
                                        >
                                          {column.dataField === "checkbox" ? (
                                            <input
                                              className="deposit-checkbox"
                                              type="checkbox"
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {column.formatter
                                            ? column.formatter(row, rowIndex)
                                            : row[column.dataField]}
                                          {column.dataField === "dropdown" && (
                                            <CustomDropdown
                                              permission={
                                                props.depositsPermissions.actions
                                              }
                                              status={row.status}
                                              approve={() =>
                                                depositApproveModal(row)
                                              }
                                              reject={() => depositReject(row)}
                                            />
                                          )}
                                        </Td>
                                      ))}
                                    </Tr>
                                  ))}
                              </Tbody>
                            )}
                          </Table>
                          <CustomPagination
                            {...props}
                            setSizePerPage={setSizePerPage}
                            sizePerPage={sizePerPage}
                            onChange={loadTotalFeeCollected}
                            docs={props.feeCollected}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {
                <ReceiptModal
                  content={selectedContent}
                  open={detailsModal}
                  onClose={() => setDetailsModal(false)}
                />
              }
              {approveModal && (
                <TransactionModal
                  show={approveModal}
                  title="Approve Deposit"
                  showAmount={true}
                  toggle={() => {
                    setApproveModal(!approveModal);
                  }}
                  t={props.t}
                  onSubmit={approveDeposit}
                  data={selectedDeposit}
                ></TransactionModal>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {showNotication && (
        <Notification
          open={showNotication}
          close={closeNotifaction}
          message="Transaction Approved"
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  fetchLoading: state.adminWalletReducer.loading,
  collectedFeesDocs: state.adminWalletReducer.collectedFeesDocs,
  totalfeesCollected: state.adminWalletReducer.totalfeesCollected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForexDepositsGatewaysStart: () =>
    dispatch(fetchForexDepositsGatewaysStart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdminWallet));
