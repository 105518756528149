
import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
  Card,
  Collapse
} from "reactstrap";

import React, { 
  useState, 
  useEffect, 
} from "react";
import { 
  AvForm, 
  AvField,
  AvRadioGroup,
  AvRadio
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { editFeeGroupStart } from "store/feeGroups/actions";
import classnames from "classnames";
import { editFeeConfigurationStart } from "store/feeConfiguration/actions";
import { fetchAssetsStart } from "store/assests/actions";
function FeeConfigurationEdit(props) {
  
  const { open, selectedItem = {}, onClose, disabled } = props;
  
  const { value: v, minValue } = selectedItem;
  const [amount, setAmount] = useState(0);
  const [onChainfee, setOnChainfee] = useState();
  const [minDeposit, setMinDeposit] = useState();
  const [minWithdrawal, setMinWithdrawal] = useState();
  const [maxWithdrawal, setMaxWithdrawal] = useState();
  const [isPercentage, setIsPercentage] = useState(true);
  const [isDefault, setIsDefault] = useState();
  const [col1, setcol1] = useState(true);
  const t_col1 = () => {
    setcol1(!col1);
  };
  const [value, setValue] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  useEffect(()=>{
    setIsPercentage(selectedItem.isPercentage);
  }, [selectedItem.isPercentage]);
  useEffect(()=>{
    setIsDefault(selectedItem.isDefault);
  }, [selectedItem.isDefault]);
 
 
  const dispatch = useDispatch();
  const updateFeeGroup = (event, values)=>{
    const { isPercentage, minValue, maxValue, title, value, isDefault } = values;
    event.preventDefault();
    let marketsObject = {};
    props.assets.forEach((asset, i)=>{
      marketsObject = {
        ...marketsObject,
        [`${asset.name}`]:{
          value : values[`fee${i}`],
          minValue : values[`minAmount${i}`],
          maxValue :values[`maxAmount${i}`]
        }
      };
    });
    dispatch(editFeeConfigurationStart(selectedItem._id, {
      isDefault,
      isPercentage,
      minValue,
      maxValue,
      title,
      value,
      wallets:{ ...marketsObject }
    }
    ));
  };
  
  useEffect(()=>{
    loadAssests(1);
    console.log(props.selectedItem);
  }, [1]);
  
  const loadAssests = ( page, limit) => {
    dispatch(fetchAssetsStart({
      limit:10000,
      page
    })) ;
  };
  return (
    <React.Fragment >
      
      <Modal isOpen={open} toggle={onClose} centered={true} size="lg" style=
        {{
          maxWidth: "700px",
          width: "100%"
        }}>
        <ModalHeader toggle={onClose} tag="h4">
          {props.t("Update Fee Configuration")}
        </ModalHeader>
        <ModalBody  >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              updateFeeGroup(e, {
                ...v,
                isPercentage:isPercentage,
                isDefault:isDefault
              });
            }}
          >
            <Row>
              
              <Col >
                <div className="mb-3">
                  <AvField
                    name="title"
                    label={props.t("Title")}
                    placeholder={props.t("Enter title")}
                    type="text"
                    value = {selectedItem.title}
                    errorMessage={props.t("Enter Valid title")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="mb-3">
                <input 
                  checked={isDefault}
                  type="checkbox"
                  name="isDefault"
                  onChange={()=>setIsDefault(preValue=>!preValue)} 
                  value={isDefault ? "True" : "False"} />
                <Label check for="isDefault">Is Default</Label>
              </div>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <AvRadioGroup
                    inline
                    name="isPercentage"
                    required
                    errorMessage={props.t("Invalid type")}
                    onChange={(e) => {
                      setIsPercentage(e.target.value);
                    }}
                  >
                    <AvRadio label={props.t("Percentage")} value={isPercentage == true ? 1 : 0} />
                    <AvRadio label={props.t("Amount")} value={isPercentage !== true ? 1 : 0} />
                  </AvRadioGroup>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className= "d-flex flex-column justify-content-center"><label>Fees Value:</label></Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="amount"
                    label={props.t("Value")}
                    placeholder={props.t("Enter Amount")}
                    type="number"
                    min="0"
                    value={v ? v.$numberDecimal : ""}
                    validate = {{
                      required :{ value:true }
                    }} 
                    onChange = {(e)=>setAmount(e.target.value)}
                  />
                </div>
              </Col>
              <Col > 
                <div className="mb-3">
                  <AvField
                    name="minValue"
                    label={props.t("Min Value")}
                    placeholder={props.t("Enter Min")}
                    type="number"
                    min="0"
                    value={ minValue ? minValue.$numberDecimal : ""}
                    onChange = {(e)=>setMinAmount(e.target.value)}
                    validate = {{
                      required :{ value:true }
                    }} 
                  />
                </div>
              </Col>
            </Row> 
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>OnChain Fees:</label></Col>
              <Col>
                <div className="mb-3">
                  <AvField
                    name="onChainfee"
                    //label={props.t("Max Value")}
                    placeholder={props.t("Enter OnChain Fees")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Valid OnChain Fees")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setOnChainfee(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Minimum Deposit:</label></Col>
              <Col>
                <div className="mb-3">
                  <AvField
                    name="minDeposit"
                    //label={props.t("Max Value")}
                    placeholder={props.t("Enter Minimum Deposit")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Valid Minimum Deposit")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setMinDeposit(e.target.value)}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Minimum Withdrawal:</label></Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="minWithdrawal"
                    //label={props.t("Min value")}
                    placeholder={props.t("Enter Min. Withdrawal")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter valid Minimum Withdrawal")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setMinWithdrawal(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Maximum Withdrawal:</label></Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="maxWithdrawal"
                    //label={props.t("Min value")}
                    placeholder={props.t("Enter Maximum Withdrawal")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Maximum Withdrawal")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setMaxWithdrawal(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            
          
            <Col xl={12}>
              <Card>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames(
                          "accordion-button",
                          "fw-medium",
                          { collapsed: !col1 }
                        )}
                        type="button"
                        onClick={t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        Symbols/Wallets
                      </button>
                    </h2>

                    <Collapse isOpen={col1} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          {props.assets.map((asset, i)=>{ 
                            const { symbol } = asset;
                            return (
                              <Row key={asset._id}>
                                <Col className="d-flex flex-column justify-content-center"><label>{symbol}</label></Col>
                                <Col>
                                  <AvField 
                                    name={`fee${i}`} 
                                    label="Fees  Value" 
                                    type="number"
                                    min="0"
                                    defaultValue={ v ? v.$numberDecimal : ""} 
                                    validate = {{
                                      required :{ value:true }
                                    }} 
                                    value={value}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`onChainfee${i}`}
                                    label="OnChain Fee"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Max")}
                                    value={onChainfee}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`minDeposit${i}`}
                                    label="Min. Deposit"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Max")}
                                    value={minDeposit}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`minWithdrawal${i}`}
                                    label="Min. Withdrawal"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Mi")}
                                    value={minWithdrawal}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`maxWithdrawal${i}`}
                                    label="Max. Withdrawal"
                                    type="number"
                                    min="0"
                                    //placeholder = {props.t("Enter Min")}
                                    validate={{
                                      required: { value: true }
                                    }}
                                    value={maxWithdrawal}></AvField>
                                </Col>
                              </Row>
                            );
                          })}   
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Card>
            </Col>
            <div className='text-center pt-3 p-2'>
              <Button  disabled={disabled} type="submit" color="primary" className="">
                {props.t("Edit")}
              </Button>
            </div>
          </AvForm>
          {
            props.error && (
              <UncontrolledAlert color="danger">
                <i className="mdi mdi-block-helper me-2"/>
                {props.t(props.error)}
              </UncontrolledAlert>
            )
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  error: state.feeConfigurationReducer.error,
  showEditSuccessMessage: state.feeConfigurationReducer.showEditSuccessMessage,
  markets: state.marketsReducer.markets || [],
  assets: state.assetReducer.assets || [],
});

export default connect(mapStateToProps, null)(withTranslation()(FeeConfigurationEdit));