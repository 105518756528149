
import {
  ADD_CHAIN_CLEAR, ADD_NEW_CHAIN, ADD_NEW_CHAIN_SUCCESS, API_ERROR, DELETE_CHAIN_DONE, DELETE_CHAIN_START, EDIT_CHAIN_DONE, EDIT_CHAIN_START, FETCH_CHAIN_START, FETCH_CHAIN_SUCCESS
} from "./actionsType";
export const fetchChainsStart = (params)=>{
  return {
    type:FETCH_CHAIN_START,
    payload:params
  };
};
export const fetchChainsSuccess = (data)=>{
  return {
    type:FETCH_CHAIN_SUCCESS,
    payload:data
  };
};
export const addNewChain = (newNetwork)=>{
  return {
    type:ADD_NEW_CHAIN,
    payload:{ newNetwork }
  };
};

export const addNewChainSuccess = (newNetwork)=>{
  
  return {
    type:ADD_NEW_CHAIN_SUCCESS,
    payload:newNetwork
    
  };
};
export const editChainStart = (params = {})=>{
  return {
    type:EDIT_CHAIN_START,
    payload:params
  };
};
export const editChainSuccess = (data) =>{
  return {
    type:EDIT_CHAIN_DONE,
    payload:data
  };
};
export const deleteChainStart = (params)=>{
  return {
    type:DELETE_CHAIN_START,
    payload:params
  };
};
export const deleteChainDone = (data)=>{
  return {
    type:DELETE_CHAIN_DONE,
    payload:data
  };
};  
export const chainEditModalClear = (data)=>{
  return {
    type:ADD_CHAIN_CLEAR,
    payload:data
  };
};
export const addChainModalClear = (data)=>{
  return {
    type:ADD_CHAIN_CLEAR,
    payload:data
  };
};
export const apiError = (error)=>{
  return {
    type:API_ERROR,
    payload:{ error }
  };
};
