import { fetchTotalFeeCollected } from "apis/admin-wallet";
import { 
  takeEvery, 
  call, 
  put,
  delay
} from "redux-saga/effects";
import { fetchTotalFeeCollectedsSuccess } from "./actions";
import { FETCH_TOTAL_FEE_COLLECTED_START } from "./actionTypes";
import { apiError } from "store/actions";

function * getTotalFeeCollected({ payload :{ params } }){
  try {
    const result = yield call(fetchTotalFeeCollected, params);
    yield put(fetchTotalFeeCollectedsSuccess(result));
  } catch (error){
    yield put(apiError(error));
  }
}
function * AdminWalletSaga(){
  yield takeEvery(FETCH_TOTAL_FEE_COLLECTED_START, getTotalFeeCollected);
}
export default AdminWalletSaga;