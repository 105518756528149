import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Input, Label, Spinner,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import { fetchAccountTypes, updateAccountType } from "store/actions";
import { Link } from "react-router-dom";
import useModal from "hooks/useModal";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import Sidebar from "components/PayFiniticDashboardlLayout/Sidebar";
import AddressManagement from "pages/AddressManagement/AddressManagementComponent";
import AddressManagementComponent from "pages/AddressManagement/AddressManagementComponent";


function PayFiniticAddressManagement(props) {
  const [accountType, setAccountType] = useState();
  const [walletOption, setWalletOption] = useState("");
  const [durationOption, setDurationOption] = useState("");
  const [timeOption, setTimeOption] = useState("");
  const [amountOption, setAmountOption] = useState("");
  const [editModal, toggleEditModal] = useModal();

  // const {
  //   loading,
  //   docs,
  //   page,
  //   totalDocs,
  //   totalPages,
  //   hasNextPage,
  //   hasPrevPage,
  //   limit,
  //   nextPage,
  //   pagingCounter,
  //   prevPage,
  //   deleteLoading,
  //   deleteClearingCounter,
  //   // roles,
  //   // editClearingCounter,
  //   teamsPermissions
  // } = useSelector((state) => ({
  //   loading: state.teamsReducer.loading || false,
  //   docs: state.teamsReducer.docs || [],
  //   page: state.teamsReducer.page || 1,
  //   totalDocs: state.teamsReducer.totalDocs || 0,
  //   totalPages: state.teamsReducer.totalPages || 0,
  //   hasNextPage: state.teamsReducer.hasNextPage,
  //   hasPrevPage: state.teamsReducer.hasPrevPage,
  //   limit: state.teamsReducer.limit,
  //   nextPage: state.teamsReducer.nextPage,
  //   pagingCounter: state.teamsReducer.pagingCounter,
  //   prevPage: state.teamsReducer.prevPage,
  //   deleteLoading: state.teamsReducer.deleteLoading,
  //   deleteClearingCounter: state.teamsReducer.deleteClearingCounter,
  //   // roles: state.teamsReducer.rolesData,
  //   clearingCounter: state.teamsReducer.clearingCounter,
  //   // editClearingCounter: state.teamsReducer.editClearingCounter,
  //   teamsPermissions: state.Profile.teamsPermissions || {}
  // }));
  const cryptoWalletsColumns = [
    {
      dataField: "wallets",
      text: "Crypto Wallets",
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "balance",
      text: "Balance",
      formatter: (item) => (
        captilazeFirstLetter(item.title)
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (item) => (
        <Link className="New Payment" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => { setAccountType(item); toggleEditModal() }}
          ></i>
        </Link>
      )
    },

  ];
  const fiatWalletsColumns = [
    {
      dataField: "wallets",
      text: "Fiat Wallets",
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "balance",
      text: "Balance",
      formatter: (item) => (
        captilazeFirstLetter(item.title)
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (item) => (
        <Link className="New Payment" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => { setAccountType(item); toggleEditModal() }}
          ></i>
        </Link>
      )
    },

  ];
  const dispatch = useDispatch();
  
  const [sizePerPage, setSizePerPage] = useState(10);
  const WalletsOptionGroup = [
    {
      label: "USD", 
      value:"usd"
    },
    {
      label: "ETH", 
      value:"eth"
    },
    {
      label: "BTC", 
      value:"btc"
    },
  ];
  const durationOptionGroup = [
    {
      label: "Last Week", 
      value:"lastWeek"
    },
    {
      label: "Last Month", 
      value:"lastMonth"
    },
    {
      label: "Last 3 Months", 
      value:"last3Months"
    },
  ];
  const timeOptionGroup = [
    {
      label: "Week", 
      value:"week"
    },
    {
      label: "Month", 
      value:"month"
    },
    {
      label: "Year", 
      value:"year"
    },
  ];
  const amountOptionGroup = [
    {
      label: "Amount", 
      value:"amount"
    },
  ];
  //   useEffect(() => {
  //     loadFeeGroups(1, sizePerPage);
  //   }, [sizePerPage, 1]);
  //   useEffect(()=>{
  //     dispatch(fetchMarketsStart({
  //       limit:1000,
  //       page:1
  //     }));
  //   }, []);
  //  const loadFeeGroups = (page, limit) => {
  //     dispatch(fetchFeeGroupStart({
  //       page,
  //       limit
  //     }));
  //   };
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Pay Finitic Address Management
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Pay Finitic Address Management</h2>
          <Row>
            <Col className="col-12">
              <Card>
                {/* <CardHeader className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <CardTitle className="color-primary">{("Merchants")} ({props.totalDocs})</CardTitle>
                    <AddMerchantModal show={merchantShow}/>
                  </div>
                </CardHeader> */}
                <Row>
                  <Col lg='12'>
                    <AddressManagementComponent/>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  );
}

export default PayFiniticAddressManagement;
