import React, { useEffect, useState } from "react";
import { 
  Row, 
  Col, 
  Card,
  CardBody, 
  CardTitle, 
  CardSubtitle 
} from "reactstrap";

import CountriesMap from "./CountriesMap";
import Reminders from "./Reminders";
import Requests from "./Requests";
import Leads from "./Leads";
import Clients from "./Clients";
import KYC from "./KYC";
import TransactionsStats from "./TransactionsStats/";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactionsFilterStart } from "store/transactions/action";
const categories = {
  day: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  week: ["Sat", "Sun", "Mon", "Tue", "Wed", "Thr", "Fri"],
  month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
  year: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
};
const categoriesFilterType = {
  day: "hour",
  week: "day",
  month: "day",
  year: "month",
};

const OperationsTab = () => {
  const [transctionFilterType, setTransctionFilterType] = useState("week");
  const [deposits, setDeposits] = useState();
  const [withdrawls, setWithdrawls] = useState();
  const dispatch = useDispatch();
  
  const { clientTransactions } = useSelector((state) => (state.clientTransactionsReducer));

  useEffect(()=>{
    dispatch(fetchTransactionsFilterStart({ period: transctionFilterType }));
  }, [transctionFilterType]);


  useEffect(()=>{
    let deposits = [];
    deposits = [...Array(categories?.[transctionFilterType]?.length)];
    let withdrawls = [];
    withdrawls = [...Array(categories?.[transctionFilterType]?.length)];
    clientTransactions?.deposits?.forEach(deposit=>{
      const spliceIndex = transctionFilterType === "week" ? 
        deposit?.[categoriesFilterType?.[transctionFilterType]] - (new Date()).getDate() :
        deposit?.[categoriesFilterType?.[transctionFilterType]];
      deposits?.splice(spliceIndex, 1, Number(deposit?.totalAmount));
    });
    deposits = deposits?.map(deposit => deposit ? deposit : 0);
    const depositTotal = deposits?.reduce((deposit, total) => total + deposit);
    if (depositTotal !== 0) {
      // deposits = deposits?.map(deposit => ((deposit / depositTotal) * 100)?.toFixed(2) );
    }
    setDeposits(deposits);
    clientTransactions?.withdrawls?.forEach(deposit=>{
      const spliceIndex = transctionFilterType === "week" ? 
        deposit?.[categoriesFilterType?.[transctionFilterType]] - (new Date()).getDate() :
        deposit?.[categoriesFilterType?.[transctionFilterType]];
      withdrawls?.splice(spliceIndex, 1, Number(deposit?.totalAmount?.$numberDecimal));
    });
    withdrawls = withdrawls?.map(withdrawl => withdrawl ? withdrawl : 0);
    const withdrawlsTotlal = withdrawls?.reduce((deposit, total) => total + deposit);
    // withdrawls = withdrawls?.map(deposit => ((deposit / withdrawlsTotlal) * 100)?.toFixed(2) );

    setWithdrawls(withdrawls);
  }, [clientTransactions]);
  return (
    <>
      {/* <Row className="col-card-same-height">
        <Col xl={6} xs={12} className="col mb-4">
          <CountriesMap />
        </Col>
        <Col xl={3} xs={6} className="col mb-4">
          <Reminders type={1} />
        </Col>
        <Col xl={3} xs={6} className="col mb-4">
          <Reminders type={0} />
        </Col>
      </Row> */}
      {/* <Row className="col-card-same-height">
        <Col xl={6} xs={12} className="col mb-4">
          <Requests />
        </Col>
        <Col xl={3} xs={6} className="col mb-4">
          <Leads />
        </Col>
        <Col xl={3} xs={6} className="col mb-4">
          <Clients />
        </Col>
      </Row> */}
      {/* <Row className="col-card-same-height">
        <Col sm={6} xs={12} className="col mb-4">
          <Card className="card-animate">
            <CardBody>
              <CardTitle>
                <h5>{("Deposits, Withdrawals and Transfers ")}</h5>
                <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                  <button type="button" className={"btn btn-info" }  >Week</button>
                  <button type="button" className={"btn btn-info" }  >Month</button>
                  <button type="button" className={"btn btn-primary"} >Year</button>
                </div>
              </CardTitle>
              <CardSubtitle className="mb-3">
              </CardSubtitle>
              <ReactApexChart
                options={
                  {
                    chart: {
                      height: 400,
                      type: "line",
                      dropShadow: {
                        enabled: false,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                      },
                      toolbar: {
                        show: true
                      }
                    },
                    colors: ["#21C78F", "#B11233", "#0D86D5"],
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: "smooth"
                    },
                    markers: {
                      size: 1
                    },
                    xaxis: {
                      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
                      title: {
                        text: "Month"
                      }
                    },
                    yaxis: {
                      title: {
                        text: "Amount"
                      },
                      min: 5,
                      max: 40
                    },
                    legend: {
                      position: "top",
                      horizontalAlign: "right",
                      floating: true,
                      offsetY: -25,
                      offsetX: -5
                    }
                  }}
                series={[
                  {
                    name: "Deposits",
                    data: [2, 29, 33, 36, 32, 32, 33]
                  },
                  {
                    name: "Transfers",
                    data: [10, 15, 5, 15, 18, 25, 25]
                  },
                  {
                    name: "Withdrawals",
                    data: [12, 11, 14, 18, 17, 13, 13]
                  }]}
                type="line"
                height={400}
              />
            </CardBody>
          </Card>
        </Col>
        <Col sm={6} className="col mb-4">
          <Card className="card-animate">
            <CardBody>
              <CardTitle>
                <h5>{("IB Summary")}</h5>
                <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                  <button type="button" className={"btn btn-info" }  >Week</button>
                  <button type="button" className={"btn btn-info" }  >Month</button>
                  <button type="button" className={"btn btn-primary"} >Year</button>
                </div>
              </CardTitle>
              <CardSubtitle className="mb-3">
              </CardSubtitle>
              <ReactApexChart
                options={
                  {
                    chart: {
                      height: 400,
                      type: "line",
                      dropShadow: {
                        enabled: false,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                      },
                      toolbar: {
                        show: true
                      }
                    },
                    colors: ["#21C78F", "#B11233", "#0D86D5"],
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: "smooth"
                    },
                    markers: {
                      size: 1
                    },
                    xaxis: {
                      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
                      title: {
                        text: "Month"
                      }
                    },
                    legend: {
                      position: "top",
                      horizontalAlign: "right",
                      floating: true,
                      offsetY: -25,
                      offsetX: -5
                    }
                  }}
                series={[
                  {
                    name: "No. of IBs",
                    data: [1, 3, 8, 15, 32, 64, 128]
                  },
                  {
                    name: "Rebate",
                    data: [1, 20, 120, 200, 680, 2890, 9504]
                  },
                  {
                    name: "Commission",
                    data: [1, 15, 100, 250, 700, 3450, 8540]
                  }]}
                type="line"
                height={400}
              />
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row className="col-card-same-height">
        {/* <Col sm={6} xs={12} className="col mb-4">
          <Card className="card-animate">
            <CardBody>
              <CardTitle>
                <h5>{("All Trades ")}</h5>
                <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                  <button type="button" className={"btn btn-info" }  >Week</button>
                  <button type="button" className={"btn btn-info" }  >Month</button>
                  <button type="button" className={"btn btn-primary"} >Year</button>
                </div>
              </CardTitle>
              <CardSubtitle className="mb-3">
              </CardSubtitle>
              <ReactApexChart
                options={
                  {
                    chart: {
                      height: 400,
                      type: "area"
                    },
                    colors: ["#21C78F", "#B11233"],
                    dataLabels: {
                      enabled: true
                    },
                    stroke: {
                      curve:  "smooth"
                    },
                    xaxis: {
                      type: "datetime",
                      categories: ["2023-01-23T00:00:00.000Z", "2023-02-23T01:30:00.000Z", "2023-03-23T02:30:00.000Z", "2023-04-23T03:30:00.000Z", "2023-05-23T04:30:00.000Z", "2023-06-19T05:30:00.000Z", "2023-07-19T06:30:00.000Z"],
                    },
                    tooltip: {
                      x: {
                        format: "dd/MM/yy HH:mm"
                      },
                    },
                  }}
                series={[
                  {
                    name: "Open Trades",
                    data: [0, 2, 10, 15, 42, 109, 100]
                  }, {
                    name: "Closed Trades",
                    data: [0, 1, 4, 12, 34, 52, 41]
                  }]}
                type="area"
                height={400}
              />
            </CardBody>
          </Card>
        </Col> */}
        <Col sm={12} className="col mb-4">
          <Card className="card-animate">
            <CardBody>
              <CardTitle>
                <h5>{("Transactions")}</h5>
                <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                  {/* <button type="button" onClick={(e)=> setTransctionFilterType("day")} className={`btn btn-info ${transctionFilterType === "day" ? "btn-primary" : ""}`}>Day</button> */}
                  <button type="button" onClick={(e)=> setTransctionFilterType("week")} className={`btn btn-info ${transctionFilterType === "week" ? "btn-primary" : ""}`}>Week</button>
                  <button type="button" onClick={(e)=> setTransctionFilterType("month")} className={`btn btn-info ${transctionFilterType === "month" ? "btn-primary" : ""}`}>Month</button>
                  <button type="button" onClick={(e)=> setTransctionFilterType("year") }className={`btn btn-info ${transctionFilterType === "year" ? "btn-primary" : ""}`}>Year</button>
                </div>
              </CardTitle>
              <CardSubtitle className="mb-3">
              </CardSubtitle>
              {
                (deposits && withdrawls) &&
                <ReactApexChart
                  options={
                    {
                      chart: {
                        height: 350,
                        type: "bar",
                      },
                      plotOptions: {
                        bar: {
                          borderRadius: 0,
                          dataLabels: {
                            position: "top", // top, center, bottom
                          },
                        }
                      },
                      dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                          return val + "$";
                        },
                        offsetY: -20,
                        style: {
                          fontSize: "12px",
                          colors: ["#304758"]
                        }
                      },
                      
                      xaxis: {
                        categories: [...categories[transctionFilterType]],
                        position: "top",
                        axisBorder: {
                          show: false
                        },
                        axisTicks: {
                          show: false
                        },
                        crosshairs: {
                          fill: {
                            type: "gradient",
                            gradient: {
                              colorFrom: "#D8E3F0",
                              colorTo: "#BED1E6",
                              stops: [0, 100],
                              opacityFrom: 0.4,
                              opacityTo: 0.5,
                            }
                          }
                        },
                        tooltip: {
                          enabled: true,
                        }
                      },
                      yaxis: {
                        axisBorder: {
                          show: true
                        },
                        axisTicks: {
                          show: true,
                        },
                        labels: {
                          show: true,
                          formatter: function (val) {
                            return val + "$";
                          }
                        }
                      
                      }
                    }}
                  series={[
                    {
                      name: "Deposits",
                      data: deposits
                    },
                    {
                      name: "Withdrawls",
                      data: withdrawls
                    }]}
                  type="bar"
                  height={400}
                />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="col-card-same-height">
        {/* <Col sm={6} xs={12} className="col mb-4">
          <TransactionsStats />
        </Col> */}
        {/* <Col sm={6} xs={12} className="col mb-4">
          <KYC />
        </Col> */}
      </Row>
    </>
  );
};

export default OperationsTab;