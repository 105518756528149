export const FETCH_WALLET_SUM_START = "FETCH_WALLET_SUM_START";
export const FETCH_WALLET_SUM_SUCCESS = "FETCH_WALLET_SUM_SUCCESS";
export const FETCH_WALLET_START = "FETCH_WALLET_START";
export const FETCH_WALLET_SUCCESS = "FETCH_WALLET_SUCCESS";
export const CLEAR_WALLETS = "CLEAR_WALLETS";

// fetch client wallet details
export const FETCH_CLIENT_WALLETS_REQUESTED = "FETCH_CLIENT_WALLET_REQUESTED";
export const FETCH_CLIENT_WALLETS_SUCCESS = "FETCH_CLIENT_WALLET_SUCCESS";
export const FETCH_CLIENT_WALLETS_FAIL = "FETCH_CLIENT_WALLET_FAIL";

export const CHANGE_STATUS_WALLET_START = "CHANGE_STATUS_WALLET_START";
export const CHANGE_STATUS_WALLET_END = "CHANGE_STATUS_WALLET_END";

// add new wallet 
export const ADD_CLIENT_WALLET_REQUESTED = "ADD_CLIENT_WALLET_REQUESTED";
export const ADD_CLIENT_WALLET_SUCCESS = "ADD_CLIENT_WALLET_SUCCESS";
export const ADD_CLIENT_WALLET_FAIL = "ADD_CLIENT_WALLET_FAIL";
export const ADD_CLIENT_WALLET_CLEAR = "ADD_CLIENT_WALLET_CLEAR";

export const CONVERT_WALLET_START = "CONVERT_WALLET_START";
export const CONVERT_WALLET_SUCCESS = "CONVERT_WALLET_SUCCESS";
export const CONVERT_WALLET_ERROR = "CONVERT_WALLET_ERROR";
export const ERROR_CLEAR = "ERROR_CLEAR";