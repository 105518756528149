import React, { useEffect, useState } from "react";
import { 
  useDispatch, 
  useSelector
} from "react-redux";
import { Link } from "react-router-dom";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader 
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import AssetEdit from "./AssetEdit";
import DeleteModal from "components/Common/DeleteModal";
import { useTranslation, withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import { deleteChainStart, fetchChainsStart } from "store/chain/actions";
import usePermissions from "routes/permissions";
import NetworkAdd from "./NetworkAdd";

function NetworkList(props){
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { symbolsPermissions } = usePermissions();
  const { update, delete:deletePermission } = symbolsPermissions;
  const { t } = useTranslation();
  const {
    loading,
    networks,
    page,
    totalDocs,
    totalPages,
    hasNextPage,
    hasPrevPage,
    nextPage,
    pagingCounter,
    prevPage,
    deleteLoading,
    deleteModalClear
  } = useSelector(state => ({
    loading: state.chainReducer.loading || false,
    networks: state.chainReducer.chains || [],
    page: state.chainReducer.page || 1,
    totalDocs: state.chainReducer.totalDocs || 0,
    totalPages: state.chainReducer.totalPages || 0,
    hasNextPage: state.chainReducer.hasNextPage,
    hasPrevPage: state.chainReducer.hasPrevPage,
    nextPage: state.chainReducer.nextPage,
    pagingCounter: state.chainReducer.pagingCounter,
    prevPage: state.chainReducer.prevPage,
    deleteLoading:state.chainReducer.deleteLoading,
    deleteModalClear:state.chainReducer.deleteModalClear,
  }));
  const columns = [
    {
      dataField:"checkbox",
      text: <input type="checkbox" id="check-all-assets" onChange={()=>checkAllBoxes("check-all-assets", ".asset-check-box")}/>
    },
    {
      dataField: "createdAt",
      text: t("Created Date"),
      formatter: (val) => formatDate(val.createdAt)
    }, 
    {
      dataField:"name",
      text:t("Name"),
      
    },
    {
      dataField:"symbol",
      text:t("Symbol")
    },
    {
      dataField: "shortName",
      text: t("Network Name"),
    
    },
    {
      dataField: "tokens",
      text: t("Tokens"),
      formatter:(val)=>(`${val?.tokens ? val.tokens?.map((token)=>token.name).join(", ") : "" }`)
    },
    // {
    //   dataField:"minAmount",
    //   text:t("Min Withdrawal"),
    //   formatter:(val)=>(`${val?.minAmount?.withdrawal ? val.minAmount.withdrawal : ""}`)
    // },  
    // {
    //   dataField: "fee",
    //   text: "Deposit Fee",
    //   formatter:(val) => (`${val?.fee?.deposit ? val.fee.deposit : "" }`),
    // },
    // {
    //   dataField:"fee",
    //   text:t("Withdrawal Fee"),
    //   formatter:(val)=>(`${val?.fee?.withdrawal ? val.fee.withdrawal : ""}`)
    // },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text:t("Action"),
      formatter: (item) => (
        <div className="text-center gap-3">
          TODO
          {/* <Link className="text-success" to="#">
            <i
              className={`mdi mdi-pencil font-size-18 ${!update ? "d-none" : ""}`}
              id="edittooltip"
              onClick={()=>{
                setSelectedSymbol(item) ;
                setEditModal(true);
              }}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className={`mdi mdi-delete font-size-18 ${!deletePermission ? "d-none" : ""}`}
              id="deletetooltip"
              onClick={()=>{
                setSelectedSymbol(item);
                setDeleteModal(true);
              }}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ];

  const [sizePerPage, setSizePerPage] = useState(10);
  
  const dispatch = useDispatch();

  useEffect(()=>{
    loadNetworks(1, sizePerPage);
  }, [sizePerPage, 1]);
  
  const loadNetworks = ( page, limit) => {
    dispatch(fetchChainsStart({
      limit,
      page
    })) ;
  };
  const deleteSymbol = ()=>{
  
    dispatch(deleteChainStart(selectedSymbol._id));
  };
  useEffect(()=>{
    
    if (props.deleteModalClear && deleteModal) {
      setDeleteModal(false);
    }
  }, [props.deleteModalClear]);
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Network
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{t("Networks")}</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <CardTitle className="color-primary">{t("Network List")} ({totalDocs})</CardTitle>
                    <NetworkAdd />
                  </div>
                  
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}
                          {!loading && networks.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`}>
                                  { column.dataField === "checkbox" ? <input type="checkbox" className="asset-check-box"/> : ""}
                                  { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {
                          ...{
                            totalDocs,
                            page,
                            totalPages,
                            hasNextPage,
                            hasPrevPage,
                            nextPage,
                            prevPage,
                          }
                        }
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadNetworks}
                        docs={networks}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {<AssetEdit open={editModal} symbol={selectedSymbol} onClose={()=>setEditModal(false)}/>}
          {<DeleteModal loading={deleteLoading} onDeleteClick = {deleteSymbol} show={deleteModal} symbol={selectedSymbol} onCloseClick={()=>setDeleteModal(false)}/>}
        </div>
      </div>
    </React.Fragment>
  );
  
}


export default (withTranslation()(NetworkList));
