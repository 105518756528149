import { FETCH_TOTAL_FEE_COLLECTED_START, FETCH_TOTAL_FEE_COLLECTED_SUCCESS } from "./actionTypes";

export const fetchTotalFeeCollectedStart = (params)=>{
  return {
    type: FETCH_TOTAL_FEE_COLLECTED_START,
    payload:{ params }
  };
};
export const fetchTotalFeeCollectedsSuccess = (data)=>{
  return {
    type:FETCH_TOTAL_FEE_COLLECTED_SUCCESS,
    payload:data
  };
};
