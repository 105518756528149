export const FETCH_CHAIN_START = "FETCH_CHAIN_START";
export const FETCH_CHAIN_SUCCESS = "FETCH_CHAIN_SUCCESS";
export const ADD_NEW_CHAIN = "ADD_NEW_CHAIN";
export const ADD_NEW_CHAIN_SUCCESS = "ADD_NEW_CHAIN_SUCCESS";
export const ADD_CHAIN_CLEAR = "ADD_CHAIN_CLEAR";
export const EDIT_CHAIN_START = "EDIT_CHAIN_START";
export const EDIT_CHAIN_DONE = "EDIT_CHAIN_DONE";
export const EDIT_CHAIN_CLEAR = "EDIT_CHAIN_CLEAR";

export const DELETE_CHAIN_START = "DELETE_CHAIN_START";
export const DELETE_CHAIN_DONE = "DELETE_CHAIN_DONE";

export const API_ERROR = "API_ERROR";