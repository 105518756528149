import {
  useDispatch, connect, useSelector 
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row, 
  Label
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation, withTranslation } from "react-i18next";
import { fetchMarkupsStart } from "store/markups/actions";
import { showErrorNotification } from "store/actions";

function TokenList(props) {
  return <></>;
}

function NetworkAdd(props){

  const [addModal, setAddUserModal] = useState(false);
  const [hasTokens, setHasTokens] = useState(false);
  const [tokens, setTokens] = useState([]);
  
  const dispatch = useDispatch();
  const { create } = props.symbolsPermissions;


  useEffect(() => {
    addModal && dispatch(showErrorNotification("Not implemented yet"));
  }, [addModal]);

  const handleAddLead = (event, values) => {
    event.preventDefault(); 
    const formData = new FormData();

    formData.append("symbol",  values.symbol);
    formData.set("minAmount", JSON.stringify({
      deposit :values.minDepositAmount,
      withdrawal:values.minWithdrawAmount
    }));
    formData.set("fee", JSON.stringify({
      deposit:values.depositFee,
      withdrawal:values.withdrawFee
    }));
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("markup", values.markup);
    formData.append("explorerLink", values.explorerLink);
    // dispatch(addNewSymbol(formData));
    
  }; 

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  useEffect(() => {
    if (props.clearModal  && addModal) {
      
      setAddUserModal(false);
      
    }
  }, [props.clearModal]);

  useEffect(()=>{
    dispatch(
      fetchMarkupsStart()
    );  
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment >
      <Button
        color="primary"
        className={`btn btn-primary ${!create ? "d-none" : ""}`} 
        onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"></i>
        {props.t("Add New Network")}
      </Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true} size="lg">
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Network")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAddLead(e, v);
            }}
            id="form"
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label={props.t("Name")}
                    placeholder={props.t("Enter Name")}
                    type="text"
                    errorMessage={props.t("Enter name of the symbol")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
              <Col md="6"> 
                <div className="mb-3">
                  <AvField
                    name="symbol"
                    label={props.t("Symbol")}
                    placeholder={props.t("Enter Symbol")}
                    type="text"
                    errorMessage={props.t("Enter symbol")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="cryptoapiName"
                    label={props.t("Crypto API Name")}
                    placeholder={props.t("Enter Crypto API Name")}
                    type="text"
                    errorMessage={props.t("Enter Crypto API Name")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="coin"
                    label={props.t("Coin Name")}
                    placeholder={props.t("Enter Coin Name")}
                    type="text"
                    errorMessage={props.t("Enter Coin Name")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row> 
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="testnet"
                    label={props.t("TestNet Name")}
                    placeholder={props.t("Enter TestNet Name")}
                    type="text"
                    errorMessage={props.t("Enter TestNet Name")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="mainnet"
                    label={props.t("MainNet Name")}
                    placeholder={props.t("Enter MainNet Name")}
                    type="text"
                    errorMessage={props.t("Enter MainNet Name")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row> 
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="blockchain"
                    label={props.t("Blockchain Transaction URL")}
                    placeholder={props.t("Enter Blockchain Transaction URL")}
                    type="text"
                    errorMessage={props.t("Enter Blockchain Transaction URL")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="address"
                    label={props.t("Blockchain Address URL")}
                    placeholder={props.t("Enter Blockchain Address URL")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter valid deposit fee")}
                    validate = {{
                      required :{ value:true }
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="shortName"
                    label={props.t("Shortname")}
                    placeholder={props.t("Enter Shortname")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Shortname")}
                    validate = {{
                      required :{ value:true }
                    }}
                  />
                </div>
              </Col>
              <Col md="3"> 
                <div className="mb-3">
                  <Label>
                    {t("Is Active ?")}
                  </Label>
                  <AvField
                    name="active"
                    label={props.t("Is Active ?")}
                    type="checkbox"
                  />
                </div>
              </Col>
              <Col md="3"> 
                <div className="mb-3">
                  <Label>
                    {t("Has Tokens ?")}
                  </Label>
                  <AvField
                    name="hasToken"
                    label={props.t("Has Tokens ?")}
                    type="checkbox"
                    value={hasTokens}
                    onChange = {() => { setHasTokens(!hasTokens) }}
                  />
                </div>
              </Col>
            </Row>
            {
              hasTokens && <div className="mb-3">
                <TokenList tokens={tokens} setTokens={setTokens} />
              </div>
            }
            <div className='text-center pt-3 p-2'>
              <Button  disabled = {props.disableAddButton} type="submit" color="primary" className="">
                {props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.t(props.error)}
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  error: state.assetReducer.error,
  symbolsPermissions: state.Profile.symbolsPermissions || {},
  disableAddButton: state.assetReducer.disableAddButton,
  clearModal: state.assetReducer.clearModal 
});
export default connect(mapStateToProps, null)(withTranslation()(NetworkAdd));