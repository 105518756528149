import { 
  takeEvery, 
  call, 
  put,
  delay
} from "redux-saga/effects";
import { 
  FETCH_FEE_COLLECTED_START,
  ADD_FEES_COLLECTED_START,
  EDIT_FEE_COLLECTED_START,
  DELETE_FEE_COLLECTED_START,
  FETCH_FEE_COLLECTED_AMOUNT_START
} from "./actionsType";
import { 
  apiError,
  addModalClear,
  editModalClear,
  deleteModalClear,
  deleteFeeCollectedSuccess,
  editFeeCollectedSuccess,
  addFeeCollectedSuccess,
  fetchFeeCollectedsSuccess,
  fetchFeeCollectedsAmountSuccess,
} from "./actions";
import { 
  showSuccessNotification 
} from "store/notifications/actions";
import { 
  addFeeCollected, deleteFeeCollected, fetchFeeCollected, fetchFeeCollectedAmounts, updateFeeCollected 
} from "apis/fee-collected";

function * getFeeCollected({ payload :{ params } }){
  try {
    const result = yield call(fetchFeeCollected, params);
    yield put(fetchFeeCollectedsSuccess(result));
  } catch (error){
    yield put(apiError(error));
  }
}
function * getFeeCollectedAmount(){
  try {
    const result = yield call(fetchFeeCollectedAmounts);
    yield put(fetchFeeCollectedsAmountSuccess(result));
  } catch (error){
    yield put(apiError(error));
  }
}
function * addNewFeesCollected ({ payload }){
  try {
    const data = yield call(addFeeCollected, payload);
    const { status, result } = data;
    if (status === true){
      yield put(addFeeCollectedSuccess(result));
      yield put(showSuccessNotification("Fee Collected has been added successfully!"));
      yield delay(1000);
      yield put(addModalClear());
    }
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}
function * editFeeCollected({ payload }){
  try {
    yield call(updateFeeCollected, payload);
    const { body, id } = payload;
    yield put(editFeeCollectedSuccess({
      _id:id, 
      ...body
    }));
    yield put(editModalClear());
    yield put(showSuccessNotification("Fee Collected has been updated successfully!"));
  } catch (error){
    yield put(apiError(error));
    yield delay(2000);
    yield put(apiError(""));
  }
}
function * deleteFeeCollecteds ({ payload : { id } }){
  try {
    yield call(deleteFeeCollected, id);
    yield put(deleteFeeCollectedSuccess(id));
    yield put(showSuccessNotification("Fee Collected has been deleted successfully!"));
    yield delay(1000);
    yield put(deleteModalClear());
  } catch (error){
    yield put(apiError(error));
    yield delay(1000);
    yield put(apiError(""));
  }
}
function * FeeCollectedSaga(){
  yield takeEvery(FETCH_FEE_COLLECTED_START, getFeeCollected);
  yield takeEvery(FETCH_FEE_COLLECTED_AMOUNT_START, getFeeCollectedAmount);
  yield takeEvery (ADD_FEES_COLLECTED_START, addNewFeesCollected);
  yield takeEvery(EDIT_FEE_COLLECTED_START, editFeeCollected);
  yield takeEvery (DELETE_FEE_COLLECTED_START, deleteFeeCollecteds);
}
export default FeeCollectedSaga;