const initalState = {
  loading:false,
  error:"",
  feeCollected :[],
  successMessage:""
};
const adminWalletReducer = (state = initalState, action)=>{
  switch (action.type){
    case "FETCH_TOTAL_FEE_COLLECTED_START":
      state = {
        ...state,
        loading:true,
        error:""
      };
      break;
    case "FETCH_TOTAL_FEE_COLLECTED_SUCCESS":
      state = {
        ...state,
        loading:false,
        collectedFeesDocs: [...action?.payload?.result?.results],
        totalfeesCollected: action?.payload?.result?.totalCollectedFees,
      };
      break;
    default :
      state = { ...state };
  }
  return state;
};
export default adminWalletReducer;