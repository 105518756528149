import {
  call, put, takeEvery, delay
} from "redux-saga/effects";
import {
  addChainModalClear, addNewChain, apiError, chainEditModalClear, deleteChainDone, editChainSuccess, fetchChainsStart,
  fetchChainsSuccess
} from "./actions";

import {
  ADD_NEW_CHAIN, DELETE_CHAIN_DONE, EDIT_CHAIN_START, FETCH_CHAIN_START
} from "./actionsType";
import { showSuccessNotification } from "store/notifications/actions";

import {
  getChains, addNewChain as addNewChainAPI,
  updateChain,
  deleteChain, 
} from "apis/chains";

function * fetchChains(params){
  try {
    const data = yield call(getChains, params);
    yield put(fetchChainsSuccess(data));
  } catch (error){
    yield put(apiError(error));
  }
  

}
function * addNewChainSaga({ payload :{ newSymbol } }){
  try {
    const data = yield call(addNewChainAPI, newSymbol);
    const { status } = data;
    const { result } = data;
    
    if (status){
      yield put(addNewChain(result ));
      yield put(showSuccessNotification("Asset has been added successfully!"));
      yield delay(1000);
      yield put(addChainModalClear());
    }
    
  } catch (error){
    
    yield put(apiError("Please Enter valid data"));
    yield delay(2000);
    yield put(apiError(""));
  }
}

function * editChain(params){
  const { payload } = params;
  const { id,  jsonData } = payload;
  try {
    const returnedData = yield call(updateChain, payload);
    const { status } = returnedData;
    if (status){
      const { result } = returnedData;
      const { data } = result;
      const { image } = data;
      yield put(editChainSuccess({
        id,
        jsonData,
        image
      }));
    }
    
    yield put(showSuccessNotification("Asset has been updated successfully!"));
    yield delay(1000);
    yield put(chainEditModalClear());
  } catch (error){
    yield put(apiError("Please Enter Valid data"));
  }
  
}
function * deleteChainSaga(params){
  try {
    const data = yield call(deleteChain, params);

    const { result } = data;
    yield put(deleteChainDone({
      result,
      id:params.payload,
      
    }));
    yield  put(showSuccessNotification("Asset has been deleted successfully!"));
  } catch (error){
    yield put(apiError("An error occurred while deleting this record"));
  }
  

}
function *chainSaga(){
  yield  takeEvery(FETCH_CHAIN_START, fetchChains);
  yield  takeEvery(ADD_NEW_CHAIN, addNewChainSaga);
  yield  takeEvery(EDIT_CHAIN_START, editChain);
  yield takeEvery(DELETE_CHAIN_DONE, deleteChainSaga);
}
export default chainSaga;