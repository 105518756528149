
import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
  Collapse,
  Card
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvRadio,
  AvRadioGroup
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { addFeesGroupStart } from "store/feeGroups/actions";
import { addFeesConfigurationStart } from "store/feeConfiguration/actions";
import { fetchAssetsStart } from "store/assests/actions";

function FeeConfigurationAdd(props) {

  const [addModal, setAddUserModal] = useState(false);
  const [isPercentage, setIsPercentage] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [col1, setcol1] = useState(true);
  const { create } = props.feeGroupsPermissions;
  const [amount, setAmount] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [onChainfee, setOnChainfee] = useState(0);
  const [minDeposit, setMinDeposit] = useState(0);
  const [minwithdrawal, setMinwithdrawal] = useState(0);
  const [maxWithdrawal, setMaxWithdrawal] = useState(0);
  const dispatch = useDispatch();

  const handleAddFeesConfiguration = (event, values) => {

    event.preventDefault();
    const { title, isPercentage, amount, onChainfee, minDeposit, maxWithdrawal, minWithdrawal, isDefault } = values;
    let marketsObject;
    props.assets.forEach((asset, i) => {
      marketsObject = {
        ...marketsObject,
        [`${asset.symbol}`]: {
          value: values[`fee${i}`],
          minValue: values[`minValue${i}`],
          onChainfee: values[`onChainfee${i}`],
          minDeposit: values[`minDeposit${i}`],
          minWithdrawal: values[`minWithdrawal${i}`],
          maxWithdrawal: values[`maxWithdrawal${i}`]
        }
      };
    });
    dispatch(addFeesConfigurationStart({
      isPercentage: isPercentage == 1 ? true : false,
      isDefault,
      value: amount,
      minValue,
      onChainfee,
      minDeposit,
      maxWithdrawal,
      minWithdrawal,
      title,
      wallets: { ...marketsObject }
    }));
  };

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };
  const t_col1 = () => {
    setcol1(!col1);
  };

  useEffect(() => {
    if (!props.showAddSuccessMessage && addModal) {
      setAddUserModal(false);
    }
  }, [props.showAddSuccessMessage]);

  useEffect(() => {
    loadAssests(1);
  }, [1]);

  const loadAssests = (page, limit) => {
    dispatch(fetchAssetsStart({
      limit: 100000,
      page
    }));
  };
  useEffect(() => {
    console.log("props.assets", props.assets);
  }, [props.assets]);

  return (
    <React.Fragment >
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}>
        <i className="bx bx-plus me-1" />
        {props.t("Add New Fees Configuration")}
      </Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true} size="lg" style=
        {{
          maxWidth: "700px",
          width: "100%"
        }}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Fees Configuration")}
        </ModalHeader>
        <ModalBody   >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAddFeesConfiguration(e, v);
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <AvField
                    name="title"
                    label={props.t("Title")}
                    placeholder={props.t("Enter title")}
                    type="text"
                    errorMessage={props.t("Enter Valid title")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <AvGroup check>
                    <AvInput type="checkbox" name="isDefault" onClick={() => setIsDefault(preValue => !preValue)} value={isDefault ? "true" : "false"} />
                    <Label check for="checkItOut">Is Default</Label>
                  </AvGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <AvRadioGroup
                    inline
                    name="isPercentage"
                    required
                    errorMessage={props.t("Invalid type")}
                    onChange={(e) => {
                      setIsPercentage(e.target.value);
                    }}
                  >
                    <AvRadio label={props.t("Percentage")} value={1} />
                    <AvRadio label={props.t("Amount")} value={0} />
                  </AvRadioGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Fees Value:</label></Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="amount"
                    //label={props.t("Value")}
                    placeholder={props.t("Enter Amount")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter valid amount")}
                    validate={{
                      required: { value: true },
                    }}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="minValue"
                    //label={props.t("Value")}
                    placeholder={props.t("Enter Min Amount")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter minimum amount")}
                    validate={{
                      required: { value: true },
                    }}
                    onChange={(e) => setMinValue(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>OnChain Fees:</label></Col>
              <Col>
                <div className="mb-3">
                  <AvField
                    name="onChainfee"
                    //label={props.t("Max Value")}
                    placeholder={props.t("Enter OnChain Fees")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Valid OnChain Fees")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setOnChainfee(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Minimum Deposit:</label></Col>
              <Col>
                <div className="mb-3">
                  <AvField
                    name="minDeposit"
                    //label={props.t("Max Value")}
                    placeholder={props.t("Enter Minimum Deposit")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Valid Minimum Deposit")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setMinDeposit(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Minimum Withdrawal:</label></Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="minWithdrawal"
                    //label={props.t("Min value")}
                    placeholder={props.t("Enter Min. Withdrawal")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter valid Minimum Withdrawal")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setMinwithdrawal(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center"><label>Maximum Withdrawal:</label></Col>
              <Col >
                <div className="mb-3">
                  <AvField
                    name="maxWithdrawal"
                    //label={props.t("Min value")}
                    placeholder={props.t("Enter Maximum Withdrawal")}
                    type="number"
                    min="0"
                    errorMessage={props.t("Enter Maximum Withdrawal")}
                    validate={{
                      required: { value: true }
                    }}
                    onChange={(e) => setMaxWithdrawal(e.target.value)}
                  />
                </div>
              </Col>
            </Row>


            <Col>
              <Card>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames(
                          "accordion-button",
                          "fw-medium",
                          { collapsed: !col1 }
                        )}
                        type="button"
                        onClick={t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        Symbols/Wallets
                      </button>
                    </h2>

                    <Collapse isOpen={col1} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">

                          {props.assets.map((asset, i) => {
                            const { symbol } = asset;
                            return <div key={asset._id}>
                              <Row className="mb-3">
                                <Col className="d-flex flex-column justify-content-center"><label>{symbol}</label></Col>
                                <Col>
                                  <AvField
                                    name={`fee${i}`}
                                    label="Fees Value"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Amount")}
                                    value={amount}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`minValue${i}`}
                                    label="Min Value"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Amount")}
                                    value={minValue}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`onChainfee${i}`}
                                    label="OnChain Fee"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Max")}
                                    value={onChainfee}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`minDeposit${i}`}
                                    label="Min. Deposit"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Max")}
                                    value={minDeposit}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`minWithdrawal${i}`}
                                    label="Min. Withdrawal"
                                    type="number"
                                    min="0"
                                    validate={{
                                      required: { value: true }
                                    }}
                                    //placeholder = {props.t("Enter Mi")}
                                    value={minwithdrawal}></AvField>
                                </Col>
                                <Col>
                                  <AvField
                                    name={`maxWithdrawal${i}`}
                                    label="Max. Withdrawal"
                                    type="number"
                                    min="0"
                                    //placeholder = {props.t("Enter Min")}
                                    validate={{
                                      required: { value: true }
                                    }}
                                    value={maxWithdrawal}></AvField>
                                </Col>
                              </Row>
                            </div>;
                          })}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Card>
            </Col>
            <div className='text-center pt-3 p-2'>
              <Button disabled={props.addButtonDisabled} type="submit" color="primary" className="">
                {props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {
            props.error && (
              <UncontrolledAlert color="danger">
                <i className="mdi mdi-block-helper me-2" />
                {props.t(props.error)}
              </UncontrolledAlert>
            )
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  error: state.feeConfigurationReducer.error,
  showAddSuccessMessage: state.feeConfigurationReducer.showAddSuccessMessage,
  addButtonDisabled: state.feeConfigurationReducer.addButtonDisabled,
  feeGroupsPermissions: state.Profile.feeGroupsPermissions || {},
  markets: state.marketsReducer.markets || [],
  assets: state.assetReducer.assets || [],
});

export default connect(mapStateToProps, null)(withTranslation()(FeeConfigurationAdd));