import * as axioHelper from "./api_helper";
import qs from "qs";

export async function fetchFeeCollected (params){
  try {
    const result = await axioHelper.get(`/fee-collected?${qs.stringify(params)}`);
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}

export async function fetchFeeCollectedAmounts (){
  try {
    const result = await axioHelper.get("/fee-collected/fees-amounts");
    return result;
  } catch (error){
    throw new Error("Error happened while fetching data");
  }
}

export async function updateFeeCollected ({ body, id }){
  const result = await axioHelper.patch(`/fee-collected/${id}`, body);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
}

export async function addFeeCollected (payload){
  const result = await axioHelper.post("/fee-collected", payload);
  if (result.code === 422){
    throw new Error(result.message);
  }
  return result;
}

export async function deleteFeeCollected ( id ){
  try {
    const result = await axioHelper.del(`/fee-collected/${id}`);
    return result;
  } catch (error){
    throw new Error("Error happened while deleting data");
  }
}